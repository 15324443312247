<template>
    <div>
        <div class="container-geral">
            <template v-for="(tarefa, keyTarefa) in tarefas" :key="keyTarefa">
                <div class="container-tarefa">
                    <div
                        class="corredores"
                        :class="{
                            'corredores-retirada': isRetirada(tarefa[0].tipo_tarefa_id),
                        }"
                    >
                        <h2
                            class="corredores-title"
                            :class="{
                                'title-retirada': isRetirada(tarefa[0].tipo_tarefa_id),
                            }"
                        >
                            {{ categoriaFormatada(keyTarefa) }}
                        </h2>
                        <span class="numbers">
                            {{ Object.keys(tarefa).length }}
                        </span>
                    </div>

                    <div class="produtos">
                        <router-link
                            v-for="(produto, keyProduto) in tarefa"
                            :key="keyProduto"
                            class="produto1"
                            :to="{
                                name: 'tarefas.verificacao',
                                params: {
                                    codigo: produto.codigo_de_barras,
                                    validade: produto.data_validade,
                                },
                            }"
                        >
                            <div class="container-img">
                                <img
                                    :src="
                                        'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' +
                                        produto.codigo_de_barras +
                                        '.webp'
                                    "
                                    @error="replaceByDefault"
                                    :alt="produto.Descricao"
                                />
                            </div>
                            <div class="nome-validade">
                                <h2
                                    :class="{
                                        'produto-alerta': isRetirada(produto.tipo_tarefa_id),
                                    }"
                                >
                                    {{ produto.descricao }}
                                </h2>
                                <h3
                                    class="validade-tarefa"
                                    :class="{
                                        'validade-retirada': isRetirada(tarefa[keyProduto].tipo_tarefa_id),
                                    }"
                                >
                                    {{ convertDate(produto.data_validade) }}
                                </h3>
                            </div>
                            <div class="btn-edit">
                                <img src="../assets/img/edit.svg" alt="botão para editar" />
                            </div>
                        </router-link>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import img from '../assets/img/broken-image.svg';

export default {
    data() {
        return {
            tarefas: null,
        };
    },

    computed: {
        getTarefas() {
            return this.$store.getters['tarefas/getTarefasCategoria'];
        },

        getCategoria() {
            return this.$store.getters['tarefas/getCategoria'];
        },
    },
    created() {
        let categoria = this.$route.params.categoria ? this.$route.params.categoria : this.getCategoria;

        this.$store.dispatch('tarefas/setCategoria', categoria);

        this.tarefas = this.getTarefas;

        if (Object.keys(this.tarefas).length === 0) {
            this.$router.go(-1);
        }
    },
    methods: {
        isRetirada(value) {
            return value == 2 ? true : false;
        },

        categoriaFormatada(modulo) {
            if (modulo.substring(0, 3).match('[0-9]')) {
                let moduloSemNumero = modulo.split('.');
                return moduloSemNumero[1];
            } else {
                return modulo;
            }
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        convertDate(date) {
            if (date != '') {
                let dateSplit = date.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },
    },
};
</script>

<style scoped>
.container-geral {
    margin-top: 66px;
    margin-bottom: 86px;
}

.container-tarefa {
    margin: 15px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px var(--cinza-escuro);
}

.corredores {
    padding: 10px 12px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #bebebe;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.corredores-title {
    text-transform: uppercase;
    color: #707070;
    font-size: 16px;
    overflow: hidden;
    max-width: 25ch;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.numbers {
    font-size: 12px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #bebebe;
    color: var(--verde);
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;
}

.produto1 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 10px;
    box-shadow: 0 25px 0px -24px #bebebe;
    position: relative;
}

.btn-edit {
    position: absolute;
    right: 10px;
    bottom: 15px;
    padding: 5px;
    border: 1px solid #dbdbdb;
    display: flex;
    border-radius: 4px;
    background-color: #fff;
}

.btn-edit img {
    width: 20px;
    height: 20px;
}

.container-img {
    display: flex;
    place-items: center;
}

.container-img img {
    width: 70px;
    height: 70px;
}

.nome-validade {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.nome-validade h2 {
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    max-width: 23ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #484848;
}

.validade-tarefa {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #484848;
}

h2.produto-alerta {
    color: #ef6969;
    font-weight: bold;
}

.corredores-retirada {
    background-color: #ef6969;
}

.title-retirada {
    color: #fff;
}

.validade-retirada {
    color: #ef6969;
}

@media (max-width: 319px) {
    .corredores-title {
        font-size: 14px;
        max-width: 24ch;
    }

    .nome-validade h2 {
        max-width: 17ch;
    }
}
</style>
